import Navbar from '../navbar/navbar.jsx';

export default function notfound(){


    return (
        <>
        <Navbar/>
        Not Found.
        </>
    );
}